import React from 'react'
import { Link } from 'gatsby'

import { useCustomer, useLogin, useAllocation } from 'gatsby-theme-harlan'

import logoSVGDark from '../images/promontory-logo-black.svg'

const Header = (props) => {
  const {
    location: { pathname },
    showLogo,
  } = props

  const { customer, isLoggedIn, isCustomerLoading } = useCustomer()
  const { logout } = useLogin()
  const { isAllocationLoading, allocationStatus, allocation } = useAllocation()

  const isBrowser = typeof window !== 'undefined'
  const isHomePage = pathname === '/'

  const shouldHideLinks = isBrowser && window.location.pathname.includes('login-by-link')

  // if (!isBrowser) {
  //   return null
  // }

  return (
    <header
      className={`main-header flex flex-col items-center
        ${isHomePage ? 'home-header' : ''}`}
    >
      {showLogo && (
        <div className={'logo-wrapper'}>
          <Link to="/">
            <img className="logo" alt="Promontory - Logo" src={logoSVGDark} width="360" height="60" />
          </Link>
        </div>
      )}

      <nav className={`main-nav top-menu justify-center ${isHomePage ? 'light-menu' : ''}`}>
        <ul className="w-full flex flex-row flex-wrap justify-center h-{24}">
          {!isCustomerLoading && (!isLoggedIn || !isAllocationLoading || 1) && (
            <>
              {isLoggedIn &&
              !isAllocationLoading &&
              (allocationStatus == 'offering' || allocationStatus == 'thank-you') ? (
                <>
                  {!allocation?.metaData?.['skip-letter'] && (
                    <li>
                      <Link to="/offering/letter" activeClassName="link_isActive">
                        Letter
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/offering/allocation" activeClassName="link_isActive">
                      Allocation
                    </Link>
                  </li>
                  <li>
                    <Link to="/offering/vintage-notes" activeClassName="link_isActive">
                      Vintage Notes
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to={isHomePage ? '#story' : '/'} activeClassName="link_isActive">
                      Story
                    </Link>
                  </li>
                  <li>
                    <Link to="/wine" activeClassName="link_isActive">
                      Wine
                    </Link>
                  </li>
                  <li>
                    <Link to="/visit" activeClassName="link_isActive">
                      Visit
                    </Link>
                  </li>

                  {isLoggedIn ? (
                    <>
                      <li>
                        <Link to="/profile" activeClassName="link_isActive">
                          Account
                        </Link>
                      </li>
                      {/* <li>
                      <a
                        className={'cursor-pointer'}
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </li> */}
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to={'/join-allocation'} activeClassName="link_isActive">
                          Allocation
                        </Link>
                      </li>
                      {/* <li>
                      <Link to="/login" activeClassName="link_isActive">
                        Members
                      </Link>
                    </li> */}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default Header
