import React from 'react'
import { Link } from 'gatsby'

import { useCustomer, useLogin, useAllocation } from 'gatsby-theme-harlan'

import { getPageSlug } from '@theme/utils/stringManipulations'
import siteConfig from '@theme/utils/siteConfig'

import logoSVGLight from '../images/promontory-logo-white.svg'

const Footer = (props) => {
  const { location } = props
  const pageSlug = getPageSlug(location.pathname)

  const { customer, isLoggedIn, isCustomerLoading } = useCustomer()
  const { logout } = useLogin()
  const { isAllocationLoading, allocationStatus } = useAllocation()

  return (
    <footer className={`${pageSlug}`}>
      <div id="footer" className={`pt-16 mt-8`}>
        <img src={logoSVGLight} alt="" className="footer-logo" width={280} />
        <div className={'nav-menu copper'}>
          {isLoggedIn ? (
            <>
              <Link to="/" className={'link cursor-pointer'} activeClassName="link_isActive">
                Story
              </Link>
              <Link to="/wine" className={'link cursor-pointer'} activeClassName="link_isActive">
                Wine
              </Link>
              <Link to="/visit" className={'link cursor-pointer'} activeClassName="link_isActive">
                Visit
              </Link>
              <Link to="/profile" className={'link cursor-pointer'} activeClassName="link_isActive">
                Account
              </Link>
              <a className={'link cursor-pointer'} onClick={logout}>
                Logout
              </a>
            </>
          ) : (
            <>
              <Link to="/" className={'link cursor-pointer'} activeClassName="link_isActive">
                Story
              </Link>
              <Link to="/wine" className={'link cursor-pointer'} activeClassName="link_isActive">
                Wine
              </Link>
              <Link to="/visit" className={'link cursor-pointer'} activeClassName="link_isActive">
                Visit
              </Link>
              <Link to="/members" className={'link cursor-pointer'} activeClassName="link_isActive">
                Members
              </Link>
            </>
          )}
        </div>

        <div className={'nav-sub copper'}>
          <Link className={'link'} to={'/bottle-verification'}>
            {'Bottle Verification'}
          </Link>
        </div>
        <div className={'contact'}>
          <div className="footer-link engravers">
            <p>1601 OAKVILLE GRADE, OAKVILLE, CA 94562</p>
          </div>

          {isLoggedIn &&
          !isAllocationLoading &&
          (allocationStatus === 'offering' || allocationStatus === 'thank-you') ? (
            <div className="footer-link engravers">
              <p>
                <a href={`tel:${siteConfig.metadata.phone}`}>{siteConfig.metadata.phone}</a>
              </p>
            </div>
          ) : (
            <div className="footer-link engravers">
              <p>
                <a href={`tel:${siteConfig.patronRelations.hospitalityPhone}`}>
                  {siteConfig.patronRelations.hospitalityPhone}
                </a>
              </p>
            </div>
          )}

          <div className="footer-link engravers">
            <p>
              <a href="mailto:INFO@PROMONTORY.WINE">INFO@PROMONTORY.WINE</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
